<template>
  <div class="overall-overscroll-behavior">
    <span
      @click="toggleSearch()"
      class="
        px-4
        bg-white
        py-2
        leading-6
        border
        rounded
        inline-flex
        justify-between
        items-center
        text-sm
        w-full
      "
    >
      <input
        disabled="disabled"
        v-model="placholderText"
        class="
          bg-transparent
          mr-2
          text-xs text-gray-500
          capitalize
          cursor-pointer
          w-full
        "
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        class="w-4 h-4 opacity-50"
      >
        <path
          data-v-802ed1ba=""
          d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
        ></path>
      </svg>
    </span>

    <!-- Search Form -->
    <div
      v-if="showSearch"
      class="
        selecet-dropdown
        overflow-y-scroll
        w-full
        z-30
        shadow-lg
        border
        rounded
        text-xs
        bg-white
        mt-3
        h-64
      "
    >
      <div class="bg-gray-200 p-2 text-xs">
        <input
          type="text"
          name=""
          placeholder="Search customer"
          class="w-full p-2"
          @input="debounceSearch"
        />
      </div>

      <!-- add customer button -->
      <div class="p-2 px-4">
        <button @click="emitGotoPage()" class="block w-full text-left text-primaryBlue">
          Add New
        </button>
      </div>

      <!-- Repeat region -->
      <button
        class="p-2 px-4 hover:bg-primaryBlue text-black hover:text-white block w-full text-left"
        v-for="(formattedString, index) in formattedList"
        :key="index"
        @click="selectRecord(index)"
      >
          {{ formattedString }}
      </button>
    </div>
  </div>
</template>

<script>
// import OfflineManager from "../index";
const debounceEvent =
  (callback, time = 250, interval) =>
  (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => callback(...args), time);
  };
import AdvancedSearch from "../advancedSearch";
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
export default {
  props: {
    customPlaceholderText: {
      type: String,
      default: 'Select Customer',
    },
    stringFields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    addRecordPage: {
      type: String,
      default: '',
    },
    // pageIndex: {
    //   type: Number,
    //   required: true,
    //   default: 1,
    // },
    // pageTotal: {
    //   type: Number,
    //   required: true,
    //   default: 1,
    // },
    module: {
      type: String,
      required: true,
      default: "customers",
    },
  },
  computed: {
    formattedList() {
      // console.log(this.dataList);
      return this.dataList.map((record) => {
        const propertyKeys = this.stringFields;
        // console.log("stringfields", propertyKeys);
        let formattedString = "";
        if (propertyKeys.length > 0) {
          propertyKeys.forEach((property) => {
            formattedString += `${record[property] || ""} `;
          });
        } else {
          const recordKeys = Object.keys(record);
          formattedString += `${record[recordKeys[0]] || ""} `;
        }
        return formattedString.trim();
        // return record
      });
    },
  },
  methods: {
    emitGotoPage() {
      this.$emit('add-record-path', this.addRecordPage)
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },
    selectRecord(index) {
      this.placholderText = this.formattedList[index];
      this.toggleSearch()
      const record = this.dataList[index]
      console.log('Selected Record: ', record)
      this.emitRecord(record)
    },
    emitRecord(record) {
      this.$emit('on-selection', record)
    },
    debounceSearch(e) {
      this.page = 0;
      const searchTerm = e.target.value
      // debugger
      debounceEvent(this.filterBySearch(searchTerm), 250)
    },
    async filterBySearch(searchTerm, doLoopThrough = true) {
      // debugger
      const results = await this.adv.searchAccrossRecordFields({
        tableName: this.module,
        searchTerm: searchTerm,
        recordFields: this.stringFields,
        pageIndex: this.page,
        loopThrough: doLoopThrough,
        resultsPerSearch: 30,
      });
      this.page = this.adv.currentIndex;
      this.dataList = results;
    }
  },
  async created() {
    this.totalRecords = await this.adv.getTotalRecords("customers");
    const results = await this.adv.paginateRecords({
      tableName: "customers",
      pageIndex: 0,
      loopThrough: true,
      resultsPerSearch: 30, // <-- first set must be at least 30 for checkScroll() to work
    });
    this.dataList = results
  },
  data() {
    return {
      showSearch: false,
      placholderText: this.customPlaceholderText,
      page: 0,
      totalRecords: 0,
      dataList: [],
      adv: new AdvancedSearch({
        BusinessId: GET_USER_BUSINESS_ID(),
        offlineManager: this.$GlobalOfflineManager,
      }),
    };
  },
};
</script>

<style scoped>
.overall-overscroll-behavior {
  overscroll-behavior: none;
}
</style>
